<template>
  <v-container
    class="container-bg rounded elevation-3"
    fluid>
    <v-row>
      <v-col cols="12">
        <h2>ประวัติการจองวัตถุดิบ</h2>
      </v-col>
      <v-col
        cols="12"
        class="header-line">
        <span>
          ข้อมูลวัตถุดิบ
        </span>
        <v-divider />
      </v-col>
      <v-col cols="3">
        <gw-product-image :src="item.imageURL" />
      </v-col>
      <v-col cols="9">
        <v-simple-table
          dense
          style="width: fit-content;">
          <template v-slot:default>
            <tbody>
              <tr>
                <th>ชื่อวัตถุดิบ</th>
                <td>{{ item.description }}</td>
              </tr>
              <tr>
                <th>รายละเอียด</th>
                <td>{{ item.description2 }}</td>
              </tr>
              <tr>
                <th>ประเภท</th>
                <td>{{ item.type }}</td>
              </tr>
              <tr>
                <th>Posting Group</th>
                <td>{{ item.genProdPostingGroup }}</td>
              </tr>
              <tr>
                <th>หน่วยซื้อสินค้า</th>
                <td class="text-capitalize">
                  {{ item.purchaseItemType }}
                </td>
              </tr>
              <tr>
                <th>Reserved</th>
                <td>
                  {{ reserved }} {{ item.purchaseUOM }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="stockMovements"
          :options.sync="options"
          :items-per-page="options.itemsPerPage"
          :server-items-length="total"
          :loading="loading"
          :footer-props="{'items-per-page-options': [15, 25, 50, 100]}"
          class="w-full elevation-1"
          @update:options="getStockMovement()"
        >
          <template #[`item.createdAt`]="{ item: movement }">
            {{ movement.createdAt | dateTimeFormat() }}
          </template>
          <template #[`item.referenceType`]="{ item: movement }">
            {{ getPurchaseType(movement.reference.type) }}
          </template>
          <template #[`item.qty`]="{ item: movement }">
            <span :class="movement.amount >= 0 ? 'success--text' : 'error--text'">{{ getQty(movement.amount) }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PurchaseItemsProvider from '@/resources/PurchaseItemsProvider'

const PurchaseItemsService = new PurchaseItemsProvider()

export default {
  data () {
    return {
      loading: false,
      total: 0,
      stockMovements: [],
      headers: [
        {
          text: 'Date',
          value: 'createdAt'
        },
        {
          text: 'Warehouse',
          value: 'warehouse.name'
        },
        {
          text: 'Type',
          value: 'referenceType'
        },
        {
          text: 'Ref.',
          value: 'reference.documentNo'
        },
        {
          text: 'Last Update By',
          value: 'updatedBy.email'
        },
        {
          text: 'QTY',
          align: 'right',
          value: 'qty'
        },
        {
          text: 'Remaining',
          value: 'onReservedQty',
          align: 'right'
        }
      ],
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['updatedAt'],
        sortDesc: [true]
      },
      item: {
        stocks: []
      }
    }
  },
  computed: {
    reserved () {
      const main = this.item.stocks.find((s) => s.warehouse.id === 0)
      return main?.onReservedQty || 0
    }
  },
  async mounted () {
    this.getItem()
    this.getStockReserve()
  },
  methods: {
    getQty (qty) {
      return `${qty >= 0 ? '+' : ''}${qty}`
    },
    async getStockReserve (clear = false) {
      if (clear) {
        this.options.page = 1
      }
      try {
        this.loading = true
        const { data: { results, total } } = await PurchaseItemsService.getStockReserve(
          this.$route.params.id,
          {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy[0],
            sortOrder: this.options.sortDesc[0] ? 'desc' : 'asc',
            search: this.inputSearch
          }
        )
        this.stockMovements = results
        this.total = total
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getItem () {
      try {
        this.loading = true

        const { data } = await PurchaseItemsService.getItemById(this.$route.params.id)

        this.item = { ...data }
      } catch (error) {
        console.error('getItem', error)
        this.$store.dispatch('setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    getPurchaseType (type) {
      switch (type) {
        case 'purchase_order': return 'Purchase Order'
        case 'assembly_order': return 'Assembly Order'
        default: return type
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
.header-line {
  position: relative;
}
.header-line span {
  font-weight: 500;
  background-color: white;
  padding-right: 8px;
  position: absolute;
  left: 12px;
  top: 0;
}
.product-image {
  display: block;
  width: 115px!important;
  padding: 5px;
  margin: 0 auto;
  border: 1px dashed #676767;
  background-color: #fff;
}
</style>

<style>
.switch-container .v-input--selection-controls {
  margin-top: 5px !important;
}
</style>
